import { createStyles } from '@mantine/core';

export const homeStyles = createStyles((theme, params: any) => ({
  sectionBackground: {
    backgroundColor: params?.backgroundColor ? theme.colors.yellow[5] : 'inherit',
  },
  buttonWithoutImage: {
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    alignItems: 'center',
    textAlign: 'center',
    [theme.fn.largerThan('sm')]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  heading: {
    textAlign: 'center',
    [theme.fn.largerThan('sm')]: {
      textAlign: 'left',
    },
  },
  sectionSpacing: {
    // paddingLeft: 0,
    // paddingRight: 0,
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
    [theme.fn.largerThan('sm')]: {
      marginTop: theme.spacing.xl,
      marginBottom: theme.spacing.xl,
    },
  },
  hideOnDesktop: {
    display: 'block',
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  showOnDesktop: {
    display: 'block',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  viewAll: {
    color: 'black',
    textDecoration: 'none',
    fontWeight: 600,
  },
}));
