import Embed from 'react-embed';
import { Container } from '@mantine/core';
import React from 'react';

export default function EmbedContent({ link }: { link: string }) {
  if (!link) {
    return <Container />;
  }
  return (
    <Container my={20}>
      <Embed url={link} />
    </Container>
  );
}
