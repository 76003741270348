import React from 'react';
import Image from 'next/image';
import { Container, Title, Text, Box, Overlay, useMantineTheme } from '@mantine/core';
import { ComponentHeroHero, Maybe, UploadFileEntityResponse } from 'types/cms';
import { useStyles } from './styles';
import strapiLoader from '../../utils/image/strapiImageLoader';

interface HeroPage extends Omit<ComponentHeroHero, 'id' | 'image'> {
  image?: Maybe<UploadFileEntityResponse> | undefined;
  children?: JSX.Element;
}

const Hero = ({ title, image, description, children }: HeroPage) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  return (
    <Box className={classes.wrapper}>
      <Image
        loader={strapiLoader}
        priority
        fill
        sizes="(min-width: 1280px) 1200px, calc(93.75vw + 19px)"
        src={image?.data?.attributes?.url || ''}
        alt={image?.data?.attributes?.alternativeText || 'Hero image'}
      />
      {title && (
        <Overlay
          gradient={`linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))`}
          zIndex={0}
        />
      )}
      <Container className={classes.content}>
        <div className={classes.cItem}>
          {title && (
            <Title className={classes.title}>
              <span>{title}</span>
            </Title>
          )}
          {description && (
            <Title order={2} fz="lg" mt="sm" color="white">
              {description}
            </Title>
          )}
        </div>

        {children ? <div className={classes.hideOnMobile}>{children}</div> : null}
      </Container>
    </Box>
  );
};

export default Hero;
