import { ComponentGlobalSectionWithText } from 'types/cms';
import {
  Container,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Center,
  Flex,
  Group,
  Stack,
} from '@mantine/core';
import React, { Fragment } from 'react';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { homeStyles } from '../../modules/shared/styles';
import Link from 'next/link';

const SectionWithText = ({ section }: ComponentGlobalSectionWithText) => {
  const { classes } = homeStyles({});

  return (
    <Container fluid px={0} className={classes.sectionSpacing}>
      {section?.map((item) => (
        <Container
          key={item?.id}
          fluid={item?.highlightBackground!}
          bg={item?.highlightBackground ? 'yellow' : ''}
          px={0}
        >
          <Container px={0}>
            <Grid>
              {getStrapiImage(item?.image?.data?.attributes!, ImageSizeNameEnum.large)?.url && (
                <Grid.Col
                  style={{ alignSelf: 'center' }}
                  p={0}
                  md={6}
                  orderMd={item?.showOnRight ? 2 : 1}
                >
                  <Image
                    src={
                      getStrapiImage(item?.image?.data?.attributes!, ImageSizeNameEnum.large)?.url
                    }
                  />
                </Grid.Col>
              )}
              <Grid.Col md={item?.image?.data ? 6 : 12} orderMd={item?.showOnRight ? 1 : 2}>
                <Container
                  className={item?.image?.data ? classes.button : classes.buttonWithoutImage}
                  mb="0"
                  p="xl"
                >
                  <Title order={1} mb="1rem">
                    {item?.heading}
                  </Title>
                  <Title order={3} fw={400} lh={1.5}>
                    <div dangerouslySetInnerHTML={{ __html: item?.text || '' }} />
                  </Title>
                  {item?.btnUrl && (
                    <Stack
                      className={item.image?.data ? classes.button : classes.buttonWithoutImage}
                    >
                      <Link passHref legacyBehavior href={{ pathname: item?.btnUrl }}>
                        <Button
                          component="a"
                          color={item?.highlightBackground ? 'red.9' : 'yellow'}
                          size="xl"
                          mt="xl"
                          radius="md"
                        >
                          <Text c={item.highlightBackground ? 'white' : 'black.9'} size="xl">
                            {item?.btnText}
                          </Text>
                        </Button>
                      </Link>
                    </Stack>
                  )}
                </Container>
              </Grid.Col>
            </Grid>
          </Container>
        </Container>
      ))}
    </Container>
  );
};

export default SectionWithText;
